import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {HttpModule} from "@angular/http";
import {HttpClientModule} from "@angular/common/http";
import {AppConfigService} from "./shared/services/app-config.service";
// import { NgZorroAntdModule } from 'ng-zorro-antd';
export function appInitializerFn (appConfig: AppConfigService) {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    // NgZorroAntdModule
  ],
  providers: [
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AppConfigService]
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
