import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'building',
    loadChildren: './building-all/building-all.module#BuildingAllModule'
  }
];

export const AppRoutingModule = RouterModule.forRoot(routes, {useHash: true});
